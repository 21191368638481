import React from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import "../components/css/publishing-store-success.css";
import "../components/css/policy.css";

export default function SharingPublicationPolicy() {
  return (
    <div className="container-main" id="page">
      <Header />
      <main className="content-main">
        <div className="row">
          <div className="col">
            <div className="policies-main px-3 py-5 mb-5">
              <h3>Thank you for using 99Gens!</h3>
              <h3>SHARING &amp; PUBLICATION POLICY</h3>
              <p>
                We encourage our customers to share their creations on social media
                and live-streaming platforms, provided that they comply with our
                guidelines to ensure satisfaction from the audience. These guidelines
                are designed to protect the intellectual property rights of 99GENS LLC
                and our customers, and to prevent any unlawful or inappropriate use of
                our content. The customer acknowledges that their sharing of our
                creations on social media and live-streaming platforms is subject to
                these guidelines.
              </p>
              <h3>1. Social Media, e-mail, electronic forms of communication</h3>
              <p>
                Any AI Generated Designs, selected from third-parties, or uploaded by
                customers, that violate our Content Policy are strictly prohibited
                from being redistributed through any electronic means, including but
                not limited to Social Media platforms, e-mail, and other forms of
                electronic communication. Such prohibited redistribution violates the
                Sharing and Publication Policy of 99GENS LLC and may result in legal
                action. The customer acknowledges that their use of our AI Generated
                Designs, selected from third-parties, or uploaded designs is subject
                to our Content Policy and any unauthorized redistribution of such
                designs is strictly prohibited.
              </p>
              <h3>2. Live Streaming</h3>
              <p>
                Any AI Generated Designs, selected from third-parties, or uploaded by
                customers, that violate our Content Policy are strictly prohibited
                from being displayed during live streams. Such prohibited display of
                content violates the Sharing and Publication Policy of 99GENS LLC and
                may result in legal action. If any prohibited content appears by
                accident during a live stream, the customer must immediately cease
                displaying such content. The customer acknowledges that their use of
                our AI Generated Designs, selected from third-parties, or uploaded
                designs during live streams is subject to our Content Policy and any
                unauthorized display of such content is strictly prohibited.
              </p>
              <h3>3. Marketing Materials</h3>
              <p>
                Any AI Generated Designs, selected from third-parties, or uploaded by
                customers, that violate our Content Policy are strictly prohibited
                from being used in marketing and publishing materials, including but
                not limited to, third-party apps and websites. This includes, but is
                not limited to, screenshots of the platform or downloaded content.
                Such prohibited use of content violates the Marketing and Publishing
                Policy of 99GENS LLC and may result in legal action. The customer
                acknowledges that their use of our AI Generated Designs, selected from
                third-parties, or uploaded designs in marketing and publishing
                materials, including third-party apps and websites, is subject to our
                Content Policy and any unauthorized use of such content is strictly
                prohibited.
              </p>
              <p>
                <a onClick={() => window.scrollTo(0, 0)}>Return to top</a>
              </p>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </div>
  );
};
